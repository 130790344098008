@font-face {
  font-family: 'Prompt';
  src: local('Prompt'), url('./assets/fonts/Prompt-Regular.ttf') format('truetype');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}