.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}

.preview {
    align-items: center;
    border: 1px solid #cbd5e0;
    /* display: flex; */
    justify-content: center;

    display: block;
    margin: 0 auto;
    max-height: 10rem;
    max-width: 42rem;
}

.ant-result-icon {
    margin-bottom: 6px
}

.ant-result-error .ant-result-icon>.anticon {
    color: #fff;
}