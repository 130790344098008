/* .KBank {
    border: 1px solid #00a950
}

.KBank .ant-table-thead > tr > th {
    color: white;
    background: #00a950 !important;
} */

.Krungthai {
    border: 1px solid #00a3e4
}

.Krungthai .ant-table-thead > tr > th {
    color: white;
    background: #00a3e4 !important;
}

.SCB {
    border: 1px solid #7A58BF
}

.SCB .ant-table-thead > tr > th {
    color: white;
    background: #7A58BF !important;
}

.BANGKOKBANK {
    border: 1px solid #039;
}

.BANGKOKBANK .ant-table-thead > tr > th {
    color: white;
    background: #039 !important;
}

.TMBBank {
    border: 1px solid #f37022
}

.TMBBank .ant-table-thead > tr > th {
    color: white;
    background: #f37022 !important;
}

/* #039 */