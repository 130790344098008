.wrap { display: 'inline-block'; position: 'relative' }

.close {
    position: 'absolute';
    top: 5px;
    right: 5px;
}

.close:hover {
    opacity: 0.7;
}